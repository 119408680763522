import { Ambiance } from './types';
import { channels } from './channels';

export const lofi: Ambiance[] = [
  {
    name: 'Relax/Study',
    code: 'jfKfPfyJRdk',
    group: 'Lofi Girl',
    livestream: true,
    channel: channels['Lofi Girl'],
  },
  {
    name: 'Synthwave Radio',
    code: '4xDzrJKXOOY',
    group: 'Lofi Girl',
    livestream: true,
    channel: channels['Lofi Girl'],
  },
  {
    name: 'dark ambient radio',
    code: 'S_MOd40zlYU',
    group: 'Lofi Girl',
    livestream: true,
    channel: channels['Lofi Girl'],
  },
  {
    name: 'Morning Coffee',
    code: '1fueZCTYkpA',
    group: 'Lofi Girl',
    channel: channels['Lofi Girl'],
  },
  {
    name: 'Cozy Winter',
    code: '_tV5LEBDs7w',
    group: 'Lofi Girl',
    channel: channels['Lofi Girl'],
  },
  {
    name: 'Soothing Breeze',
    code: 'gnZImHvA0ME',
    group: 'Lofi Girl',
    channel: channels['Lofi Girl'],
  },
  {
    name: 'Lazy Sunday',
    code: 'zFhfksjf_mY',
    group: 'Lofi Girl',
    channel: channels['Lofi Girl'],
  },
  {
    name: '1 A.M Study Session',
    code: 'lTRiuFIWV54',
    group: 'Lofi Girl',
    channel: channels['Lofi Girl'],
  },
  {
    name: 'Sleep/Chill',
    code: '28KRPhVzCus',
    group: 'Lofi Girl',
    channel: channels['Lofi Girl'],
    livestream: true,
  },
  {
    name: 'Coffee Shop Hip-Hop Beats',
    code: 'N_7cSl2oq3o',
    group: 'Coffee Shop',
    livestream: true,
    channel: channels['STEEZYASFUCK'],
  },
  {
    name: 'Lofi Hip-Hop Radio',
    code: 'rPjez8z61rI',
    group: 'Hip Hop',
    livestream: true,
    channel: channels['STEEZYASFUCK'],
  },
  {
    name: 'Rain Lofi',
    code: 'GM2QNd1D-Sc',
    group: 'Miscellaneous Lofi',
    channel: channels['lofi geek'],
  },
  {
    name: 'Lofi Hip Hop Beats 24/7 Radio',
    code: 'x0OLPEjna8A',
    group: 'Hip Hop',
    livestream: true,
    channel: channels['lofi geek'],
  },
  {
    name: 'Nintendo Lofi Study Beats',
    code: '7JMvn0wfABQ',
    group: 'Nintendo',
    channel: channels.Helynt,
  },
  {
    name: 'Late Night Gaming - Chill Lofi Beats',
    code: 'pIA9ztlyzMA',
    group: 'Miscellaneous Gaming',
    channel: channels.Helynt,
  },
  {
    name: 'Crackling Fireplace',
    code: 'yimlIZEJwPY',
    group: 'Miscellaneous Lofi',
    channel: channels['Chillhop Music'],
  },
  {
    name: 'Animal Crossing Lofi Beats',
    code: 'OPVQMGOuMYI',
    group: 'Nintendo',
    channel: channels.Helynt,
  },
  {
    name: 'Nintendo Synthwave Mix',
    code: 'jpyMaQ6afLk',
    group: 'Nintendo',
    channel: channels.Helynt,
  },
  {
    name: 'Minecraft Lofi Study Beats',
    code: 'RvLk1pGAEqA',
    group: 'Miscellaneous Gaming',
    channel: channels.Helynt,
  },
  {
    name: 'Pokémon lofi Beats',
    code: 'vR60tNCGCIE',
    group: 'Nintendo',
    channel: channels.Helynt,
  },
  {
    name: 'Chillhop - Jazzy/Lofi hip hop beats',
    code: '5yx6BWlEVcY',
    group: 'Hip Hop',
    channel: channels['Chillhop Music'],
    livestream: true,
  },
  {
    name: 'Lofi hip hop radio',
    code: '7NOSDKb0HlU',
    group: 'Hip Hop',
    channel: channels['Chillhop Music'],
    livestream: true,
  },
  {
    name: 'Chillhop Essentials Winter 2024',
    code: 'kblfv8dKAk0',
    group: 'Hip Hop',
    channel: channels['Chillhop Music'],
  },
  {
    name: 'Japanese night cafe vibes',
    code: '9FvvbVI5rYA',
    group: 'Coffee Shop',
    channel: channels['Chill with Taiki'],
    startTimeS: 5,
  },
  {
    name: 'Korean cafe vibes',
    code: 'Xry_g0Vp5MY',
    group: 'Coffee Shop',
    channel: channels['Chill with Taiki'],
  },
  {
    name: '[24/7 study with me] chill study',
    code: 'azV9PMW5-Ro',
    group: 'Miscellaneous Lofi',
    channel: channels['Chill with Taiki'],
    livestream: true,
  },
  {
    name: '24/7 CHILL LOFI HIP HOP RADIO',
    code: 'qH3fETPsqXU',
    group: 'Hip Hop',
    channel: channels['Chill with Taiki'],
    livestream: true,
  },
  {
    name: 'Chill Drive',
    code: 'kMiBrqV_4FA',
    group: 'Hip Hop',
    channel: channels['Chilli Music'],
  },
  {
    name: 'Coffee to go! jazzy lofi beats',
    code: '2gliGzb2_1I',
    group: 'Coffee Shop',
    channel: channels.TheJazzHopCafé,
  },
  {
    name: 'Overwatch - Lofi',
    code: 'dp4miziEOvQ',
    group: 'Blizzard',
    channel: channels['Overwatch League'],
  },
  {
    name: 'Overwatch 2 - Lofi',
    code: 'b_anhQUwD0Q',
    group: 'Blizzard',
    channel: channels['Overwatch League'],
  },
  {
    name: 'Diablo LoFi Beats | Ep. 1',
    code: 'P3n8vT49ueg',
    group: 'Blizzard',
    channel: channels['Overwatch League'],
  },
  {
    name: 'Diablo LoFi Beats | Ep. 2',
    code: '7lm50fJTcLo',
    group: 'Blizzard',
    channel: channels['Overwatch League'],
  },
  {
    name: 'Diablo LoFi Beats | Ep. 3',
    code: 'afp_7ekZamg',
    group: 'Blizzard',
    channel: channels['Overwatch League'],
  },
  {
    name: 'Diablo LoFi Beats | Ep. 4',
    code: 'WDzMcZFXzB0',
    group: 'Blizzard',
    channel: channels['Overwatch League'],
  },
  {
    name: 'Diablo LoFi Beats | Ep. 5',
    code: 'VSZJlDhD_ns',
    group: 'Blizzard',
    channel: channels['Overwatch League'],
  },
  {
    name: 'Diablo LoFi Beats | Ep. 6',
    code: 'q0qcCkW9KWE',
    group: 'Blizzard',
    channel: channels['Overwatch League'],
  },
  {
    name: 'World of Warcraft - Lofi',
    code: 'UWrslj9JQnc',
    group: 'Blizzard',
    channel: channels['World of Warcraft'],
  },
  {
    name: 'Zelda & Chill',
    code: 'GdzrrWA8e7A',
    group: 'Nintendo',
    channel: channels.GameChops,
  },
  {
    name: 'Zelda & Chill 2',
    code: 'rJlY1uKL87k',
    group: 'Nintendo',
    channel: channels.GameChops,
  },
  {
    name: 'Chilltendo Deluxe',
    code: 'c5V-qL1HRho',
    group: 'Nintendo',
    channel: channels.GameChops,
  },
  {
    name: 'Poké & Chill',
    code: '2DVpys50LVE',
    group: 'Nintendo',
    channel: channels.GameChops,
  },
  {
    name: 'Video Game Study Lounge',
    code: 'mF3m7Jza2uc',
    group: 'Miscellaneous Gaming',
    livestream: true,
    channel: channels.GameChops,
  },
  {
    name: 'Lord of the Rings',
    code: 'HBpUNPBA_qk',
    group: 'Miscellaneous Lofi',
    channel: channels['Ambient Worlds'],
  },
  {
    name: 'Mellow/chill instrumental beats',
    code: 'A3yOYJawFRI',
    group: 'Miscellaneous Lofi',
    livestream: true,
    channel: channels['College Music'],
  },
  {
    name: 'STUDY and WORK AMBIENCE 24/7 for DEEP FOCUS',
    code: 'Lofi Beats & Ocean Sounds',
    group: 'Study With Me',
    channel: channels['The Sherry Formula'],
  },
  {
    name: 'Good Vibes Only - Hip-Hop',
    code: 'Rvk5kBPv9RU',
    group: 'Hip Hop',
    livestream: true,
    channel: channels['LTB Music'],
  },
  {
    name: 'Coffee shop calm lofi',
    code: '78mYkbfsXRM',
    group: 'Coffee Shop',
    channel: channels['lofi geek'],
  },
  {
    name: 'Together at BlizzCon',
    code: '4NRPEe7YnCQ',
    group: 'Blizzard',
    channel: channels['World of Warcraft'],
  },
  {
    name: 'Journey to BlizzCon',
    code: 'vJpo5dW3Ze4',
    group: 'Blizzard',
    channel: channels['World of Warcraft'],
  },
  {
    name: 'Waiting for BlizzCon',
    code: '-uxi6_5cFqw',
    group: 'Blizzard',
    channel: channels['World of Warcraft'],
  },
];
